@import '../../node_modules/normalize.css/normalize.css';

$color-white: #fff7e9;
$color-black: #24221f;

html,
body {
  width: 100%;
  height: 100%;
}

body {
  background: $color-white;
  color: $color-black;
  font-family: 'Libre Baskerville', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin: 0;
}

#videoElement {
  z-index: -100;
  position: fixed;
  opacity: 0;
}

#sensorCanvas {
  position: fixed;
  z-index: 1000;
  display: none;
}

.content {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  background: $color-white;
  box-sizing: border-box;
  perspective: 1200px;
}

.screen {
  background: $color-white;
  width: 100%;
  height: 100%;
  position: absolute;
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  transition: transform ease-in 0.8s;
  overflow: hidden;
  transform: rotateY(-90deg);
  transform-origin: 0 50%;
  &:after {
    content: '';
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    position: absolute;
    top: 20px;
    left: 20px;
    pointer-events: none;
    box-sizing: border-box;
    border: 2px solid $color-black;
  }
}

.screen.waiting {
  transform: rotateY(0deg) translate3d(0, 0, 0);
}

.screen.active {
  transform: rotateY(0deg) translate3d(0, 0, 0);
}

.screen-0 {
  z-index: 13;
}

.screen-1 {
  z-index: 12;
}

.screen-2 {
  z-index: 11;
}

.screen-3 {
  z-index: 10;
}

.title {
  margin: 0;
  padding-bottom: 12px;
  font-style: italic;
  font-size: 36px;
}

.subtitle {
  padding-bottom: 48px;
  font-size: 16px;
  font-style: italic;
}

.button {
  font-weight: 700;
  border: none;
  padding: 8px 8px;
  background: transparent;
  color: $color-black;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  cursor: pointer;
  border-radius: 0;
  position: relative;
  border: 2px solid $color-black;
  border-left: none;
  border-right: none;
  &:focus {
    outline: none;
  }
}

.instructions {
  width: 80%;
  max-width: 400px;
  box-sizing: border-box;
  width: 100%;
  padding: 0;
  list-style: none;
  text-align: center;
  margin: 0;
  li {
    padding-left: 10px;
    margin: 32px 0;
    line-height: 1.6em;
    font-size: 18px;
    position: relative;

    &:before {
      content: '2.';
      display: block;
      font-weight: 700;
      font-size: 18px;
      width: 20px;
      margin: auto;
      padding-bottom: 8px;
    }
    &:first-child {
      margin-top: 0;
      &:before {
        content: '1.';
      }
    }
    &:last-child {
      &:before {
        content: '3.';
      }
    }
  }
}

.quote {
  line-height: 1.6em;
  font-size: 15px;
  text-align: center;
  padding-bottom: 24px;
  margin: 0;
}

.author {
  font-size: 20px;
  font-style: italic;
  padding-bottom: 48px;
}

.result {
  font-size: 36px;
  font-style: italic;
}
